import React, { useEffect, useState } from "react";
import Header2 from "./Header2";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchhotelBookingHistoryList } from "../reduxToolKit/slices/hotelBookingHistoryApiSlice";
import { fetchpackagebookedList } from "../reduxToolKit/slices/packagesBookedApiSlice";
import { Nav, Tab } from "react-bootstrap";
import { images } from "../utils/images";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import { fetchHotelBookingCancellation } from "../reduxToolKit/slices/cancelHotelBookingApiSlice";
import { fetchHotelBookingCancellationInternal } from "../reduxToolKit/slices/cancelHotelBookingApiSliceInternal";
import { toast } from "react-toastify";
import { cancelPackageBooking, updatePaymentType } from "../reduxToolKit/slices/cancelPackageApi";
import moment from "moment";
import { getPerformaLink, getVoucherLink } from "../reduxToolKit/slices/GetVoucherAndPerforma";
import { postPackagePayment } from "../reduxToolKit/slices/bookPackageApiSlice";
import { fetchtransactionList } from "../reduxToolKit/slices/payment/payment";

const initialState = {
    bookingId: "",
    startDate: "",
    endDate: "",
};

const HotelListingHistory = () => {
    const [iState, updateState] = useState(initialState);
    const { bookingId, startDate, endDate } = iState;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const bookingList = useSelector(
        (state) => state?.hotelBookingHistoryList?.hotelBookingHistoryList?.data
    );
    const packageList = useSelector(
        (state) => state?.bookedPackageList?.packagebooked?.data
    );
    const walletList = useSelector((state) => state?.transactionData?.transactionData)
    const { type } = useParams();
    const [activePage, updateActivePage] = useState(1);

    console.log("id", type);

    console.log("packageList", packageList);

    const handleFilter = () => {
        const data = {
            search: bookingId,
            startDate,
            endDate,
            page: activePage
        };
        dispatch(fetchpackagebookedList(data));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        updateState({
            ...iState,
            [name]: value,
        });
    };

    const paymentColor = (type) => {
        if (type === "partPayment") {
            return "orange";
        }

        if (type === "fullPayment") {
            return "green";
        }
    };

    const statusColor = (status) => {
        if (status === "CONFIRMED") {
            return "green";
        }
        if (status === "CANCELLED") {
            return "red";
        }
    };

    const handlePageChangeofPackage = (pageNumber) => {
        // let ser = pageNumber * 10;
        // updateSerialNo(ser);
        updateActivePage(pageNumber);
        let data = {
            search: "",
            startDate: "",
            endDate: "",
            page: pageNumber,
        };
        dispatch(fetchpackagebookedList(data));
    };
    useEffect(() => {
        dispatch(fetchhotelBookingHistoryList());
        dispatch(fetchtransactionList())
        const data = {
            search: "",
            startDate: "",
            endDate: "",
            page: 1,
        };
        dispatch(fetchpackagebookedList(data));
        window.scroll(0, 0);
    }, []);
    useEffect(() => {
        updateState({
            iState,
            bookingId: "",
            startDate: "",
            endDate: "",
        });
    }, [type]);

    const handleCancelBooking = (cancellationId, bookingId) => {
        console.log("Cancellation function hitted");
        dispatch(fetchHotelBookingCancellation(cancellationId)).then((res) => {
            console.log("Response", res);
            const data = {
                bookingId: bookingId,
                referenceId: cancellationId,
                status: "CANCELLED",
            };
            dispatch(fetchHotelBookingCancellationInternal(data)).then((res) => {
                if (res.payload.status == 200) {
                    dispatch(fetchhotelBookingHistoryList());
                    toast.success("Hotel Cancelled Sucessfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                    });
                }
            });
        });
    };

    const handleCancelPackacke = (bookingId) => {
        let data = {
            bookingId: bookingId,
            status: "cancelled",
        };

        dispatch(cancelPackageBooking(data)).then((res) => {
            if (res.payload.status == 200) {
                const data = {
                    search: "",
                    startDate: "",
                    endDate: "",
                    page: 1,
                };
                dispatch(fetchpackagebookedList(data));
                toast.success("Pacakage Cancelled Sucessfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
            }
        });
    };

    const handleRefresh = () => {
        const data = {
            search: "",
            startDate: "",
            endDate: "",
            page: 1,
        };
        dispatch(fetchpackagebookedList(data));
    };


    const calcBookingPrice = (item) => {
        let subtotal = Number(item) * (5 / 100)
        let gstOnService = (18 / 100) * subtotal
        let totalTax = subtotal + gstOnService
        let total = Number(item) + totalTax
        return total
    }

    const handleDownload = async (e, item) => {
        e.preventDefault(); // Prevent default anchor tag behavior

        try {
            // Hit the API to get the download URL
            const response = await dispatch(getVoucherLink(item?._id)); // Replace with your API endpoint
            console.log('Response of download voucher link: ', response)
            let downloadUrl = ""
            if (response?.payload?.status == 200) {
                downloadUrl = response?.payload?.data
            }

            // Trigger download
            const tempLink = document.createElement('a');
            tempLink.href = downloadUrl;
            tempLink.target = '_blank';
            tempLink.download = ''; // Optional: specify filename if needed
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink); // Clean up the temporary element
        } catch (error) {
            console.error('Error fetching the download URL:', error);
            alert('Failed to download the file. Please try again later.');
        }
    };

    const handlePerforma = async (e, item) => {
        e.preventDefault(); // Prevent default anchor tag behavior

        try {
            // Hit the API to get the download URL
            const response = await dispatch(getPerformaLink(item?._id)); // Replace with your API endpoint
            console.log('Response of download voucher link: ', response)
            let downloadUrl = ""
            if (response?.payload?.status == 200) {
                downloadUrl = response?.payload?.data
            }

            // Trigger download
            const tempLink = document.createElement('a');
            tempLink.href = downloadUrl;
            tempLink.target = '_blank';
            tempLink.download = ''; // Optional: specify filename if needed
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink); // Clean up the temporary element
        } catch (error) {
            console.error('Error fetching the download URL:', error);
            alert('Failed to download the file. Please try again later.');
        }
    };

    const handlePayment = (id, totalAmount, amountPaid) => {
        const amountToBePaid = totalAmount - amountPaid;
        if (amountToBePaid > walletList?.data?.currentAmount?.balance) {
            toast.error("Insufficient balance in your wallet", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
            navigate('/wallet')
        }
        const data = {
            amount: Number(amountToBePaid)//totalAmountWithTax
        }
        dispatch(postPackagePayment(data))
            .then((res) => {
                console.log('Payment deduction successful:', res);

                if (res?.payload?.status === 200) {
                    const cancelData = {
                        bookingId: id,
                        status: "fullPayment"
                    };
                    dispatch(updatePaymentType(cancelData)).then((res) => {
                        console.log('cancel response', res);
                        if (res?.payload?.code === 200) {
                            handleRefresh()
                        }
                    });
                }
            })
            .catch((error) => {
                console.error('Error during payment deduction:', error);
            });

    }

    return (
        <div>
            <Header2 />
            <main className="FilterProductsArea ">
                <sections>
                    <div className="TicketsBookingsTabs mt-5">
                        <h2>Tickets/Bookings</h2>
                    </div>
                    <div className="tab-content">
                        <Tab.Container

                            defaultActiveKey="Package"
                        >
                            <div className="TicketsBookingsTabs">
                                <Nav className="nav nav-tabs">
                                    <Nav.Item className="nav-item">
                                        <Nav.Link
                                            eventKey="hotels"
                                            as={Link}
                                            to="/hotelListingHistory/hotels"
                                            className="nav-link"
                                        >
                                            <img src={images.hotelImg}></img> Hotels
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="Flight"
                                            as={Link}
                                            to="/hotelListingHistory/flight"
                                        >
                                            <img src={images.flightIcon}></img> Flight
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="Package"
                                            as={Link}
                                            to="/hotelListingHistory/package"
                                        >
                                            <img src={images.packageIcon}></img> Package
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="Activity"
                                            as={Link}
                                            to="/hotelListingHistory/activity"
                                        >
                                            <img src={images.destination}></img> Activity
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="Transfer"
                                            as={Link}
                                            to="/hotelListingHistory/transfer"
                                        >
                                            <img src={images.hotelImg}></img> Transfer
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <Tab.Content>
                                <Tab.Pane eventKey="hotels">
                                    <div className="AccountHistoryFilter">
                                        <div className="TopMainBox">
                                            <div className="Filter">
                                                <div className="form-group">
                                                    <div className="CommonInputBox">
                                                        <h6>Booking Reference ID</h6>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Booking Id"
                                                            name="bookingId"
                                                            value={bookingId}
                                                            onChange={handleChange}
                                                            disabled={startDate || endDate}
                                                        />
                                                        <span className="CibRightOverlay">
                                                            <i className="fa fa-search" aria-hidden="true" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="InputBox">
                                                        <h6>From</h6>
                                                        <input
                                                            type="date"
                                                            className="form-control-date"
                                                            name="startDate"
                                                            value={startDate}
                                                            onChange={handleChange}
                                                            disabled={bookingId}
                                                        />
                                                        <span className="FilterIcon">
                                                            <img src={images.calendarBlueImg} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="InputBox">
                                                        <h6>To</h6>
                                                        <input
                                                            type="date"
                                                            className="form-control-date"
                                                            name="endDate"
                                                            value={endDate}
                                                            onChange={handleChange}
                                                            disabled={bookingId}
                                                        />
                                                        <span className="FilterIcon">
                                                            <img src={images.calendarBlueImg} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {bookingList?.result?.length > 0
                                        ? bookingList?.result?.map((item) => {
                                            return (
                                                <div className="AccountHistoryBox">
                                                    <div className="AhbTop">
                                                        <div className="AhbtSmall">
                                                            <p>Booking Reference ID</p>
                                                            <h5>
                                                                {item?.fullResponse?.fullResponse?.reference}
                                                            </h5>
                                                        </div>
                                                        <div className="AhbtSmall">
                                                            <p>Status</p>
                                                            <h5
                                                                className="Confirmed"
                                                                style={{
                                                                    color: statusColor(
                                                                        item?.fullResponse?.fullResponse?.status
                                                                    ),
                                                                }}
                                                            >
                                                                {item?.fullResponse?.fullResponse?.status}{" "}
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            item?.fullResponse?.fullResponse
                                                                                ?.status === "CONFIRMED"
                                                                                ? images.confirm
                                                                                : ""
                                                                        }
                                                                    />
                                                                </span>
                                                            </h5>
                                                        </div>
                                                        <div className="AhbtSmall">
                                                            <p>Agent Name</p>
                                                            <h5>
                                                                {
                                                                    item?.fullResponse?.fullResponse
                                                                        ?.clientReference
                                                                }
                                                            </h5>
                                                        </div>
                                                        <div className="AhbtSmall">
                                                            <p>Customer Name</p>
                                                            <h5>
                                                                {
                                                                    item?.fullResponse?.fullResponse?.holder
                                                                        ?.name
                                                                }
                                                            </h5>
                                                        </div>
                                                        <div className="AhbtSmall">
                                                            <p>Booking Date</p>
                                                            <h5>
                                                                {
                                                                    item?.fullResponse?.fullResponse
                                                                        ?.creationDate
                                                                }
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="AhbBottom">
                                                        <div className="AhbBottomStart">
                                                            <div className="AhbBottomStartLeft">
                                                                <figure>
                                                                    <img src={images.interior} />
                                                                </figure>
                                                            </div>
                                                            <div className="AhbBottomStartRight">
                                                                <div className="AhbBottomStartRightTop">
                                                                    <h6>
                                                                        {
                                                                            item?.fullResponse?.fullResponse?.hotel
                                                                                ?.name
                                                                        }{" "}
                                                                        <span> </span>
                                                                    </h6>
                                                                    <p>
                                                                        <span>
                                                                            <img src={images.location} />
                                                                        </span>
                                                                        {
                                                                            item?.fullResponse?.fullResponse?.hotel
                                                                                ?.destinationName
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="AhbBottomStartRightBottom">
                                                                    <p>
                                                                        1 x{" "}
                                                                        <span>
                                                                            Standard Room With 1 Double Bed
                                                                        </span>{" "}
                                                                        And Breakfast - 2 Adults
                                                                    </p>
                                                                    <p>
                                                                        <span>
                                                                            Confirmation reference from hotel:
                                                                        </span>
                                                                        {item?.fullResponse?.fullResponse?.remark}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="AhbBottomCenter">
                                                            <p>From-To</p>
                                                            <h6>
                                                                {
                                                                    item?.fullResponse?.fullResponse?.hotel
                                                                        ?.checkIn
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                    item?.fullResponse?.fullResponse?.hotel
                                                                        ?.checkOut
                                                                }
                                                            </h6>
                                                        </div>
                                                        <div className="AhbBottomEnd">
                                                            <div className="d-flex flex-column align-items-end">
                                                                <Link
                                                                    to='/performa'
                                                                    className="BrandBtn"
                                                                    state={item}
                                                                >
                                                                    Performa
                                                                </Link>
                                                                <Link
                                                                    to='/voucher'
                                                                    className="BrandBtn"
                                                                    state={item}
                                                                >
                                                                    Voucher
                                                                </Link>
                                                                <button
                                                                    className="BrandOutlineBtn"
                                                                    onClick={() =>
                                                                        handleCancelBooking(
                                                                            item?.fullResponse?.fullResponse
                                                                                ?.reference,
                                                                            item?._id
                                                                        )
                                                                    }
                                                                >
                                                                    Cancel Booking
                                                                </button>
                                                            </div>
                                                            <div className="text-right">
                                                                <p>Booking Total</p>
                                                                <h3>
                                                                    Inr.{" "}
                                                                    {item?.fullResponse?.fullResponse?.totalNet}{" "}
                                                                    <span>Incl. tax</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        : ""}
                                </Tab.Pane>
                                <Tab.Pane eventKey="Flight"></Tab.Pane>
                                <Tab.Pane eventKey="Package">
                                    <div className="AccountHistoryFilter">
                                        <div className="TopMainBox">
                                            <div className="Filter">
                                                <div className="form-group">
                                                    <div className="CommonInputBox">
                                                        <h6>Booking Reference ID</h6>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Booking Id"
                                                            name="bookingId"
                                                            value={bookingId}
                                                            onChange={handleChange}
                                                            disabled={startDate || endDate}
                                                        />
                                                        <span className="CibRightOverlay">
                                                            <i className="fa fa-search" aria-hidden="true" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="InputBox">
                                                        <h6>From</h6>
                                                        <input
                                                            type="date"
                                                            className="form-control-date"
                                                            name="startDate"
                                                            value={startDate}
                                                            onChange={handleChange}
                                                            disabled={bookingId}
                                                        />
                                                        <span className="FilterIcon">
                                                            <img src={images.calendarBlueImg} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="InputBox">
                                                        <h6>To</h6>
                                                        <input
                                                            type="date"
                                                            className="form-control-date"
                                                            name="endDate"
                                                            value={endDate}
                                                            onChange={handleChange}
                                                            disabled={bookingId}
                                                        />
                                                        <span className="FilterIcon">
                                                            <img src={images.calendarBlueImg} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="InputBox">
                                                        {/* <h6>Apply</h6> */}
                                                        <button
                                                            type="submit"
                                                            className="form-control-date"
                                                            onClick={handleFilter}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>

                                                </div>
                                                <div className="form-group">

                                                    <div className="InputBox">

                                                        <a
                                                            onClick={handleRefresh}
                                                            className="form-group packageSearch"
                                                        >
                                                            <i class="fa fa-refresh"></i>
                                                        </a>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {packageList?.result?.length > 0
                                        ? packageList?.result?.map((item) => {
                                            return (
                                                <div className="AccountHistoryBox">
                                                    <div className="AhbTop">
                                                        <div className="AhbtSmall">
                                                            <p>Booking Reference ID</p>
                                                            <h5>{item?.bookingNo}</h5>
                                                        </div>
                                                        <div className="AhbtSmall">
                                                            <p>Status</p>
                                                            <h5
                                                                className={
                                                                    item?.bookingStatus == "confirmed"
                                                                        ? "Confirmed"
                                                                        : "Cancelled"
                                                                }
                                                            >
                                                                {item?.bookingStatus
                                                                    ?.charAt(0)
                                                                    ?.toUpperCase() +
                                                                    item?.bookingStatus?.slice(1)}
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            item?.bookingStatus == "confirmed"
                                                                                ? require("../assests/images/confirm_icon.png")
                                                                                : require("../assests/images/cancel_icon.png")
                                                                        }
                                                                    />
                                                                </span>
                                                            </h5>
                                                        </div>
                                                        <div className="AhbtSmall">
                                                            <p>Agent Name</p>
                                                            <h5>{item?.users?.[0]?.firstName}</h5>
                                                        </div>
                                                        <div className="AhbtSmall">
                                                            <p>Customer Name</p>
                                                            <h5>{item?.userDetails?.[0]?.firstName}</h5>
                                                        </div>
                                                        <div className="AhbtSmall">
                                                            <p>Booking Date</p>
                                                            <h5>{moment(item?.createdAt).format('MMM D, YYYY')}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="AhbBottom">
                                                        <div className="AhbBottomStart">
                                                            <div className="AhbBottomStartLeft">
                                                                <figure>
                                                                    <img
                                                                        src={item?.package?.packageImages?.[0]}
                                                                        height="190px"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div className="AhbBottomStartRight">
                                                                <div className="AhbBottomStartRightTop">
                                                                    <h6>
                                                                        {item?.package?.packageTitle}
                                                                        {/* <span>Oyo</span> */}
                                                                    </h6>
                                                                    <p>
                                                                        <span>
                                                                            <img src={images.locationMapIcon} />
                                                                        </span>
                                                                        {item?.package?.city},{" "}
                                                                        {item?.package?.state}
                                                                    </p>
                                                                </div>
                                                                <div className="AhbBottomStartRightBottom">
                                                                    <p>
                                                                        {/* 1 x */}
                                                                        {" "}
                                                                        <span>{item?.package?.packageType}</span>{" "}
                                                                        - {item?.adult} Adults {item?.child > 0 ? `and ${item?.child} child` : null}
                                                                    </p>
                                                                    <p>
                                                                        <span>
                                                                            Confirmation reference from hotel:
                                                                        </span>{" "}
                                                                        Service is confirmed, but reference is not
                                                                        available yet. Please, check it later
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="AhbBottomCenter">
                                                            <p>From-To</p>
                                                            <h6>
                                                                {" "}
                                                                {moment(item?.selectedDate).format('MMM D, YYYY')} -{" "}
                                                                {moment(new Date(item?.selectedDate))
                                                                    .add(
                                                                        item?.package?.numberOfNights?.totalNumberofNights,
                                                                        "days"
                                                                    )
                                                                    ?.format("ll")}
                                                            </h6>
                                                        </div>
                                                        <div className="AhbBottomEnd">
                                                            <div className="d-flex flex-column align-items-end">
                                                                <a
                                                                    // href="https://auuction.s3.amazonaws.com/hotelAdmin/proforma_131-2516087_en.pdf"
                                                                    // target="_blank"
                                                                    className="BrandBtn"
                                                                    onClick={(e) => { handlePerforma(e, item) }}
                                                                >
                                                                    Performa
                                                                </a>
                                                                <a
                                                                    // href="https://auuction.s3.amazonaws.com/hotelAdmin/voucher_1312516087-H-1.pdf"
                                                                    // target="_blank"
                                                                    className="BrandBtn"
                                                                    onClick={(e) => { handleDownload(e, item) }}
                                                                >
                                                                    Voucher
                                                                </a>
                                                                {item?.bookingStatus == "confirmed" && (
                                                                    <a
                                                                        onClick={() =>
                                                                            handleCancelPackacke(item?._id)
                                                                        }
                                                                        data-toggle="modal"
                                                                        data-target="#CancelModal"
                                                                        className="BrandOutlineBtn"
                                                                    >
                                                                        Cancel Booking
                                                                    </a>
                                                                )}
                                                            </div>
                                                            <div className="text-right">
                                                                <p>Booking Total</p>
                                                                <h3
                                                                    style={{
                                                                        color: paymentColor(item?.paymentType),
                                                                    }}
                                                                >
                                                                    {/* {calcBookingPrice(item?.totalPrice)} */}
                                                                    {
                                                                        item?.totalPrice
                                                                    }
                                                                    <span>Incl. tax</span>
                                                                </h3>
                                                                {
                                                                    item?.paymentType !== 'fullPayment' ? <a
                                                                        // href="https://auuction.s3.amazonaws.com/hotelAdmin/proforma_131-2516087_en.pdf"
                                                                        // target="_blank"
                                                                        className="BrandBtn"
                                                                        onClick={() => handlePayment(item?._id, item?.totalPrice, item?.amountPaid)}
                                                                    >
                                                                        Pay {item?.totalPrice - item?.amountPaid}
                                                                    </a> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        : ""}
                                    <div>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={packageList?.total}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChangeofPackage}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Activity"></Tab.Pane>
                                <Tab.Pane eventKey="Transfer"></Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                        <div
                            className="tab-pane fade show active"
                        //id="Hotels"
                        >
                            {/* <div className="AccountHistoryFilter">
                                <div className="TopMainBox">
                                    <div className="Filter">
                                        <div className="form-group">
                                            <div className="CommonInputBox">
                                                <h6>Booking Reference ID</h6>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="E.g. 6512-SD454-22"
                                                />
                                                <span className="CibRightOverlay">
                                                    <i className="fa fa-search" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="InputBox">
                                                <h6>From</h6>
                                                <input type="date" className="form-control-date" />
                                                <span className="FilterIcon">
                                                    <img src="images/calendar_blue.png" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="InputBox">
                                                <h6>To</h6>
                                                <input type="date" className="form-control-date" />
                                                <span className="FilterIcon">
                                                    <img src="images/calendar_blue.png" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* {
                                bookingList?.result?.length > 0 ? bookingList?.result?.map((item) => {
                                    return (
                                        <div className="AccountHistoryBox">
                                            <div className="AhbTop">
                                                <div className="AhbtSmall">
                                                    <p>Booking Reference ID</p>
                                                    <h5>6512-SD454-22</h5>
                                                </div>
                                                <div className="AhbtSmall">
                                                    <p>Status</p>
                                                    <h5 className="Confirmed">
                                                        {item?.bookingStatus}{" "}
                                                        <span>
                                                            <img src="images/confirm_icon.png" />
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="AhbtSmall">
                                                    <p>Agent Name</p>
                                                    <h5>Sharnya</h5>
                                                </div>
                                                <div className="AhbtSmall">
                                                    <p>Customer Name</p>
                                                    <h5>{item?.userDetails?.[0]?.firstName}</h5>
                                                </div>
                                                <div className="AhbtSmall">
                                                    <p>Booking Date</p>
                                                    <h5>12/10/2023</h5>
                                                </div>
                                            </div>
                                            <div className="AhbBottom">
                                                <div className="AhbBottomStart">
                                                    <div className="AhbBottomStartLeft">
                                                        <figure>
                                                            <img src="images/interior.png" />
                                                        </figure>
                                                        <div className="ProductLeftOverlay">
                                                            <div className="PloImages">
                                                                <figure>
                                                                    <img src="images/small_01.png" />
                                                                </figure>
                                                                <figure>
                                                                    <img src="images/small_02.png" />
                                                                </figure>
                                                                <figure>
                                                                    <img src="images/small_03.png" />
                                                                </figure>
                                                                <figure>
                                                                    <img src="images/small_04.png" />
                                                                </figure>
                                                                <figure>
                                                                    <img src="images/small_05.png" />
                                                                </figure>
                                                            </div>
                                                            <p>9 Images</p>
                                                        </div>
                                                    </div>
                                                    <div className="AhbBottomStartRight">
                                                        <div className="AhbBottomStartRightTop">
                                                            <h6>
                                                                The People's Brownstone <span>Oyo</span>
                                                            </h6>
                                                            <p>
                                                                <span>
                                                                    <img src="images/location.png" />
                                                                </span>
                                                                1995 Broadway, New York
                                                            </p>
                                                        </div>
                                                        <div className="AhbBottomStartRightBottom">
                                                            <p>
                                                                1 x <span>Standard Room With 1 Double Bed</span> And
                                                                Breakfast - 2 Adults
                                                            </p>
                                                            <p>
                                                                <span>Confirmation reference from hotel:</span> Service is
                                                                confirmed, but reference is not available yet. Please, check
                                                                it later
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="AhbBottomCenter">
                                                    <p>From-To</p>
                                                    <h6>01/03/2023 - 03/03/2023</h6>
                                                </div>
                                                <div className="AhbBottomEnd">
                                                    <div className="d-flex flex-column align-items-end">
                                                        <a href="javascript:void(0);" className="BrandBtn">
                                                            Performa
                                                        </a>
                                                        <a href="javascript:void(0);" className="BrandBtn">
                                                            Voucher
                                                        </a>
                                                        <a
                                                            href="javascript:void(0);"
                                                            data-toggle="modal"
                                                            data-target="#CancelModal"
                                                            className="BrandOutlineBtn"
                                                        >
                                                            Cancel Booking
                                                        </a>
                                                    </div>
                                                    <div className="text-right">
                                                        <p>Booking Total</p>
                                                        <h3>
                                                            $3,000 <span>Incl. tax</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ""
                            } */}

                            {/* <div className="AccountHistoryBox">
                                <div className="AhbTop">
                                    <div className="AhbtSmall">
                                        <p>Booking Reference ID</p>
                                        <h5>6512-SD454-22</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Status</p>
                                        <h5 className="Cancelled">
                                            Cancelled{" "}
                                            <span>
                                                <img src="images/cancel_icon.png" />
                                                $30.00
                                            </span>
                                        </h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Agent Name</p>
                                        <h5>Sharnya</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Customer Name</p>
                                        <h5>Zeeshan</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Booking Date</p>
                                        <h5>12/10/2023</h5>
                                    </div>
                                </div>
                                <div className="AhbBottom">
                                    <div className="AhbBottomStart">
                                        <div className="AhbBottomStartLeft">
                                            <figure>
                                                <img src="images/interior.png" />
                                            </figure>
                                            <div className="ProductLeftOverlay">
                                                <div className="PloImages">
                                                    <figure>
                                                        <img src="images/small_01.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_02.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_03.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_04.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_05.png" />
                                                    </figure>
                                                </div>
                                                <p>9 Images</p>
                                            </div>
                                        </div>
                                        <div className="AhbBottomStartRight">
                                            <div className="AhbBottomStartRightTop">
                                                <h6>
                                                    The People's Brownstone <span>Oyo</span>
                                                </h6>
                                                <p>
                                                    <span>
                                                        <img src="images/location.png" />
                                                    </span>
                                                    1995 Broadway, New York
                                                </p>
                                            </div>
                                            <div className="AhbBottomStartRightBottom">
                                                <p>
                                                    1 x <span>Standard Room With 1 Double Bed</span> And
                                                    Breakfast - 2 Adults
                                                </p>
                                                <p>
                                                    <span>Confirmation reference from hotel:</span> Service is
                                                    confirmed, but reference is not available yet. Please, check
                                                    it later
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="AhbBottomCenter">
                                        <p>From-To</p>
                                        <h6>01/03/2023 - 03/03/2023</h6>
                                    </div>
                                    <div className="AhbBottomEnd">
                                        <div className="d-flex flex-column align-items-end">
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Performa
                                            </a>
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Voucher
                                            </a>
                                        </div>
                                        <div className="text-right">
                                            <p>Booking Total</p>
                                            <h3>
                                                $3,000 <span>Incl. tax</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="tab-pane fade" id="Flight">
                            <div className="AccountHistoryFilter">
                                <div className="TopMainBox">
                                    <div className="Filter">
                                        <div className="form-group">
                                            <div className="CommonInputBox">
                                                <h6>Booking Reference ID</h6>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="E.g. 6512-SD454-22"
                                                />
                                                <span className="CibRightOverlay">
                                                    <i className="fa fa-search" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="InputBox">
                                                <h6>From</h6>
                                                <input type="date" className="form-control-date" />
                                                <span className="FilterIcon">
                                                    <img src="images/calendar_blue.png" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="InputBox">
                                                <h6>To</h6>
                                                <input type="date" className="form-control-date" />
                                                <span className="FilterIcon">
                                                    <img src="images/calendar_blue.png" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="UpcomingExportExcel">
                                        <select>
                                            <option select="">Upcoming</option>
                                        </select>
                                        <a href="javascript:void(0);" className="ExportToExcelBtn">
                                            <img src="images/excel_icon.png" />
                                            Export to Excel
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="FlightTabBox">
                                <div className="AhbSideLeft">
                                    <div className="AhbSideSmall">
                                        <h1>12:00 pm</h1>
                                        <p>Delhi (DEL)</p>
                                    </div>
                                    <div className="AhbSideSmall Center">
                                        <figure>
                                            <img src="images/airplane_fade.png" />
                                        </figure>
                                        <p>non stop</p>
                                        <p>2h 28m</p>
                                    </div>
                                    <div className="AhbSideSmall">
                                        <h1>12:00 pm</h1>
                                        <p>Chandigarh(ICX)</p>
                                    </div>
                                </div>
                                <div className="AhbSideRight">
                                    <div className="AhbTop">
                                        <div className="AhbtSmall">
                                            <p>Booking Reference ID</p>
                                            <h5>6512-SD454-22</h5>
                                        </div>
                                        <div className="AhbtSmall">
                                            <p>Status</p>
                                            <h5 className="Confirmed">
                                                Confirmed{" "}
                                                <span>
                                                    <img src="images/confirm_icon.png" />
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="AhbtSmall">
                                            <p>Agent Name</p>
                                            <h5>Sharnya</h5>
                                        </div>
                                        <div className="AhbtSmall">
                                            <p>Customer Name</p>
                                            <h5>Zeeshan</h5>
                                        </div>
                                        <div className="AhbtSmall">
                                            <p>Booking Date</p>
                                            <h5>12/10/2023</h5>
                                        </div>
                                    </div>
                                    <div className="AhbCenter">
                                        <div className="AhbCenterStart">
                                            <figure>
                                                <img src="images/emirates_logo.png" />
                                            </figure>
                                            <p>Economy</p>
                                            <h4>Emirates A380 Airbus</h4>
                                        </div>
                                        <div className="AhbCenterCenter">
                                            <h6>Refundable</h6>
                                        </div>
                                        <div className="AhbCenterEnd">
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Performa
                                            </a>
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Voucher
                                            </a>
                                            <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#CancelModal"
                                                className="BrandOutlineBtn"
                                            >
                                                Cancel Booking
                                            </a>
                                        </div>
                                    </div>
                                    <div className="BnbdBottom">
                                        <div className="BnbdBottomLeft">
                                            <ul>
                                                <li>
                                                    <span>
                                                        <img src="images/baggage_icon.png" />
                                                    </span>
                                                    7 kg Cabin Baggage
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src="images/check_baggage_icon.png" />
                                                    </span>
                                                    15 kg 01 Check-in Baggage
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="Price">
                                            <p>Booking Total</p>
                                            <h6>
                                                <span>INR3,000</span> /Incl. tax
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="FlightTabBox">
                                <div className="AhbSideLeft">
                                    <div className="AhbSideSmall">
                                        <h1>12:00 pm</h1>
                                        <p>Delhi (DEL)</p>
                                    </div>
                                    <div className="AhbSideSmall Center">
                                        <figure>
                                            <img src="images/airplane_fade.png" />
                                        </figure>
                                        <p>non stop</p>
                                        <p>2h 28m</p>
                                    </div>
                                    <div className="AhbSideSmall">
                                        <h1>12:00 pm</h1>
                                        <p>Chandigarh(ICX)</p>
                                    </div>
                                </div>
                                <div className="AhbSideRight">
                                    <div className="AhbTop">
                                        <div className="AhbtSmall">
                                            <p>Booking Reference ID</p>
                                            <h5>6512-SD454-22</h5>
                                        </div>
                                        <div className="AhbtSmall">
                                            <p>Status</p>
                                            <h5 className="Confirmed">
                                                Confirmed{" "}
                                                <span>
                                                    <img src="images/confirm_icon.png" />
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="AhbtSmall">
                                            <p>Agent Name</p>
                                            <h5>Sharnya</h5>
                                        </div>
                                        <div className="AhbtSmall">
                                            <p>Customer Name</p>
                                            <h5>Zeeshan</h5>
                                        </div>
                                        <div className="AhbtSmall">
                                            <p>Booking Date</p>
                                            <h5>12/10/2023</h5>
                                        </div>
                                    </div>
                                    <div className="AhbCenter">
                                        <div className="AhbCenterStart">
                                            <figure>
                                                <img src="images/emirates_logo.png" />
                                            </figure>
                                            <p>Economy</p>
                                            <h4>Emirates A380 Airbus</h4>
                                        </div>
                                        <div className="AhbCenterCenter">
                                            <h6>Refundable</h6>
                                        </div>
                                        <div className="AhbCenterEnd">
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Performa
                                            </a>
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Voucher
                                            </a>
                                            <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#CancelModal"
                                                className="BrandOutlineBtn"
                                            >
                                                Cancel Booking
                                            </a>
                                        </div>
                                    </div>
                                    <div className="BnbdBottom">
                                        <div className="BnbdBottomLeft">
                                            <ul>
                                                <li>
                                                    <span>
                                                        <img src="images/baggage_icon.png" />
                                                    </span>
                                                    7 kg Cabin Baggage
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src="images/check_baggage_icon.png" />
                                                    </span>
                                                    15 kg 01 Check-in Baggage
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="Price">
                                            <p>Booking Total</p>
                                            <h6>
                                                <span>INR3,000</span> /Incl. tax
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="Package">
                            <div className="AccountHistoryFilter">
                                <div className="TopMainBox">
                                    <div className="Filter">
                                        <div className="form-group">
                                            <div className="CommonInputBox">
                                                <h6>Booking Reference ID</h6>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="E.g. 6512-SD454-22"
                                                />
                                                <span className="CibRightOverlay">
                                                    <i className="fa fa-search" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="InputBox">
                                                <h6>From</h6>
                                                <input type="date" className="form-control-date" />
                                                <span className="FilterIcon">
                                                    <img src="images/calendar_blue.png" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="InputBox">
                                                <h6>To</h6>
                                                <input type="date" className="form-control-date" />
                                                <span className="FilterIcon">
                                                    <img src="images/calendar_blue.png" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="UpcomingExportExcel">
                                        <select>
                                            <option select="">Upcoming</option>
                                        </select>
                                        <a href="javascript:void(0);" className="ExportToExcelBtn">
                                            <img src="images/excel_icon.png" />
                                            Export to Excel
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="AccountHistoryBox">
                                <div className="AhbTop">
                                    <div className="AhbtSmall">
                                        <p>Booking Reference ID</p>
                                        <h5>6512-SD454-22</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Status</p>
                                        <h5 className="Confirmed">
                                            Confirmed{" "}
                                            <span>
                                                <img src="images/confirm_icon.png" />
                                            </span>
                                        </h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Agent Name</p>
                                        <h5>Sharnya</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Customer Name</p>
                                        <h5>Zeeshan</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Booking Date</p>
                                        <h5>12/10/2023</h5>
                                    </div>
                                </div>
                                <div className="AhbBottom">
                                    <div className="AhbBottomStart">
                                        <div className="AhbBottomStartLeft">
                                            <figure>
                                                <img src="images/interior.png" />
                                            </figure>
                                            <div className="ProductLeftOverlay">
                                                <div className="PloImages">
                                                    <figure>
                                                        <img src="images/small_01.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_02.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_03.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_04.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_05.png" />
                                                    </figure>
                                                </div>
                                                <p>9 Images</p>
                                            </div>
                                        </div>
                                        <div className="AhbBottomStartRight">
                                            <div className="AhbBottomStartRightTop">
                                                <h6>
                                                    The People's Brownstone <span>Oyo</span>
                                                </h6>
                                                <p>
                                                    <span>
                                                        <img src="images/location.png" />
                                                    </span>
                                                    1995 Broadway, New York
                                                </p>
                                            </div>
                                            <div className="AhbBottomStartRightBottom">
                                                <p>7 Days 6 Nights</p>
                                                <p>
                                                    <span>Confirmation reference from hotel:</span> Service is
                                                    confirmed, but reference is not available yet. Please, check
                                                    it later
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="AhbBottomCenter">
                                        <p>From-To</p>
                                        <h6>01/03/2023 - 03/03/2023</h6>
                                    </div>
                                    <div className="AhbBottomEnd">
                                        <div className="d-flex flex-column align-items-end">
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Performa
                                            </a>
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Voucher
                                            </a>
                                            <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#CancelModal"
                                                className="BrandOutlineBtn"
                                            >
                                                Cancel Booking
                                            </a>
                                        </div>
                                        <div className="text-right">
                                            <p>Booking Total</p>
                                            <h3>
                                                $3,000 <span>Incl. tax</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="AccountHistoryBox">
                                <div className="AhbTop">
                                    <div className="AhbtSmall">
                                        <p>Booking Reference ID</p>
                                        <h5>6512-SD454-22</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Status</p>
                                        <h5 className="Cancelled">
                                            Cancelled{" "}
                                            <span>
                                                <img src="images/cancel_icon.png" />
                                                $30.00
                                            </span>
                                        </h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Agent Name</p>
                                        <h5>Sharnya</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Customer Name</p>
                                        <h5>Zeeshan</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Booking Date</p>
                                        <h5>12/10/2023</h5>
                                    </div>
                                </div>
                                <div className="AhbBottom">
                                    <div className="AhbBottomStart">
                                        <div className="AhbBottomStartLeft">
                                            <figure>
                                                <img src="images/interior.png" />
                                            </figure>
                                            <div className="ProductLeftOverlay">
                                                <div className="PloImages">
                                                    <figure>
                                                        <img src="images/small_01.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_02.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_03.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_04.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_05.png" />
                                                    </figure>
                                                </div>
                                                <p>9 Images</p>
                                            </div>
                                        </div>
                                        <div className="AhbBottomStartRight">
                                            <div className="AhbBottomStartRightTop">
                                                <h6>
                                                    The People's Brownstone <span>Oyo</span>
                                                </h6>
                                                <p>
                                                    <span>
                                                        <img src="images/location.png" />
                                                    </span>
                                                    1995 Broadway, New York
                                                </p>
                                            </div>
                                            <div className="AhbBottomStartRightBottom">
                                                <p>
                                                    1 x <span>Standard Room With 1 Double Bed</span> And
                                                    Breakfast - 2 Adults
                                                </p>
                                                <p>
                                                    <span>Confirmation reference from hotel:</span> Service is
                                                    confirmed, but reference is not available yet. Please, check
                                                    it later
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="AhbBottomCenter">
                                        <p>From-To</p>
                                        <h6>01/03/2023 - 03/03/2023</h6>
                                    </div>
                                    <div className="AhbBottomEnd">
                                        <div className="d-flex flex-column align-items-end">
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Performa
                                            </a>
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Voucher
                                            </a>
                                        </div>
                                        <div className="text-right">
                                            <p>Booking Total</p>
                                            <h3>
                                                $3,000 <span>Incl. tax</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="Activity">
                            <div className="AccountHistoryFilter">
                                <div className="TopMainBox">
                                    <div className="Filter">
                                        <div className="form-group">
                                            <div className="CommonInputBox">
                                                <h6>Booking Reference ID</h6>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="E.g. 6512-SD454-22"
                                                />
                                                <span className="CibRightOverlay">
                                                    <i className="fa fa-search" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="InputBox">
                                                <h6>From</h6>
                                                <input type="date" className="form-control-date" />
                                                <span className="FilterIcon">
                                                    <img src="images/calendar_blue.png" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="InputBox">
                                                <h6>To</h6>
                                                <input type="date" className="form-control-date" />
                                                <span className="FilterIcon">
                                                    <img src="images/calendar_blue.png" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="UpcomingExportExcel">
                                        <select>
                                            <option select="">Upcoming</option>
                                        </select>
                                        <a href="javascript:void(0);" className="ExportToExcelBtn">
                                            <img src="images/excel_icon.png" />
                                            Export to Excel
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="AccountHistoryBox">
                                <div className="AhbTop">
                                    <div className="AhbtSmall">
                                        <p>Booking Reference ID</p>
                                        <h5>6512-SD454-22</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Status</p>
                                        <h5 className="Confirmed">
                                            Confirmed{" "}
                                            <span>
                                                <img src="images/confirm_icon.png" />
                                            </span>
                                        </h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Agent Name</p>
                                        <h5>Sharnya</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Customer Name</p>
                                        <h5>Zeeshan</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Booking Date</p>
                                        <h5>12/10/2023</h5>
                                    </div>
                                </div>
                                <div className="AhbBottom">
                                    <div className="AhbBottomStart">
                                        <div className="AhbBottomStartLeft">
                                            <figure>
                                                <img src="images/interior.png" />
                                            </figure>
                                            <div className="ProductLeftOverlay">
                                                <div className="PloImages">
                                                    <figure>
                                                        <img src="images/small_01.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_02.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_03.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_04.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_05.png" />
                                                    </figure>
                                                </div>
                                                <p>9 Images</p>
                                            </div>
                                        </div>
                                        <div className="AhbBottomStartRight">
                                            <div className="AhbBottomStartRightTop">
                                                <h6>
                                                    The People's Brownstone <span>Oyo</span>
                                                </h6>
                                                <p>
                                                    <span>
                                                        <img src="images/location.png" />
                                                    </span>
                                                    1995 Broadway, New York
                                                </p>
                                            </div>
                                            <div className="AhbBottomStartRightBottom">
                                                <p>
                                                    1 x <span>Standard Room With 1 Double Bed</span> And
                                                    Breakfast - 2 Adults
                                                </p>
                                                <p>
                                                    <span>Confirmation reference from hotel:</span> Service is
                                                    confirmed, but reference is not available yet. Please, check
                                                    it later
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="AhbBottomCenter">
                                        <p>From-To</p>
                                        <h6>01/03/2023 - 03/03/2023</h6>
                                    </div>
                                    <div className="AhbBottomEnd">
                                        <div className="d-flex flex-column align-items-end">
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Performa
                                            </a>
                                            <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#CancelModal"
                                                className="BrandOutlineBtn"
                                            >
                                                Cancel Booking
                                            </a>
                                        </div>
                                        <div className="text-right">
                                            <p>Booking Total</p>
                                            <h3>
                                                $3,000 <span>Incl. tax</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="AccountHistoryBox">
                                <div className="AhbTop">
                                    <div className="AhbtSmall">
                                        <p>Booking Reference ID</p>
                                        <h5>6512-SD454-22</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Status</p>
                                        <h5 className="Cancelled">
                                            Cancelled{" "}
                                            <span>
                                                <img src="images/cancel_icon.png" />
                                                $30.00
                                            </span>
                                        </h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Agent Name</p>
                                        <h5>Sharnya</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Customer Name</p>
                                        <h5>Zeeshan</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Booking Date</p>
                                        <h5>12/10/2023</h5>
                                    </div>
                                </div>
                                <div className="AhbBottom">
                                    <div className="AhbBottomStart">
                                        <div className="AhbBottomStartLeft">
                                            <figure>
                                                <img src="images/interior.png" />
                                            </figure>
                                            <div className="ProductLeftOverlay">
                                                <div className="PloImages">
                                                    <figure>
                                                        <img src="images/small_01.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_02.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_03.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_04.png" />
                                                    </figure>
                                                    <figure>
                                                        <img src="images/small_05.png" />
                                                    </figure>
                                                </div>
                                                <p>9 Images</p>
                                            </div>
                                        </div>
                                        <div className="AhbBottomStartRight">
                                            <div className="AhbBottomStartRightTop">
                                                <h6>
                                                    The People's Brownstone <span>Oyo</span>
                                                </h6>
                                                <p>
                                                    <span>
                                                        <img src="images/location.png" />
                                                    </span>
                                                    1995 Broadway, New York
                                                </p>
                                            </div>
                                            <div className="AhbBottomStartRightBottom">
                                                <p>
                                                    1 x <span>Standard Room With 1 Double Bed</span> And
                                                    Breakfast - 2 Adults
                                                </p>
                                                <p>
                                                    <span>Confirmation reference from hotel:</span> Service is
                                                    confirmed, but reference is not available yet. Please, check
                                                    it later
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="AhbBottomCenter">
                                        <p>From-To</p>
                                        <h6>01/03/2023 - 03/03/2023</h6>
                                    </div>
                                    <div className="AhbBottomEnd">
                                        <div className="d-flex flex-column align-items-end">
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Performa
                                            </a>
                                            <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#CancelModal"
                                                className="BrandOutlineBtn"
                                            >
                                                Cancel Booking
                                            </a>
                                        </div>
                                        <div className="text-right">
                                            <p>Booking Total</p>
                                            <h3>
                                                $3,000 <span>Incl. tax</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="Transfer">
                            <div className="AccountHistoryFilter">
                                <div className="TopMainBox">
                                    <div className="Filter">
                                        <div className="form-group">
                                            <div className="CommonInputBox">
                                                <h6>Booking Reference ID</h6>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="E.g. 6512-SD454-22"
                                                />
                                                <span className="CibRightOverlay">
                                                    <i className="fa fa-search" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="InputBox">
                                                <h6>From</h6>
                                                <input type="date" className="form-control-date" />
                                                <span className="FilterIcon">
                                                    <img src="images/calendar_blue.png" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="InputBox">
                                                <h6>To</h6>
                                                <input type="date" className="form-control-date" />
                                                <span className="FilterIcon">
                                                    <img src="images/calendar_blue.png" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="UpcomingExportExcel">
                                        <select>
                                            <option select="">Upcoming</option>
                                        </select>
                                        <a href="javascript:void(0);" className="ExportToExcelBtn">
                                            <img src="images/excel_icon.png" />
                                            Export to Excel
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="AccountHistoryBox">
                                <div className="AhbTop">
                                    <div className="AhbtSmall">
                                        <p>Booking Reference ID</p>
                                        <h5>6512-SD454-22</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Status</p>
                                        <h5 className="Confirmed">
                                            Confirmed{" "}
                                            <span>
                                                <img src="images/cancel_icon.png" />
                                                $30.00
                                            </span>
                                        </h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Agent Name</p>
                                        <h5>Sharnya</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Customer Name</p>
                                        <h5>Zeeshan</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Booking Date</p>
                                        <h5>12/10/2023</h5>
                                    </div>
                                </div>
                                <div className="AhbBottom">
                                    <div className="AhbBottomStart">
                                        <div className="AhbBottomStartLeft Transfer">
                                            <figure>
                                                <img src="images/car_big_1.png" />
                                            </figure>
                                        </div>
                                        <div className="AhbBottomStartRight">
                                            <div className="AhbBottomStartRightTop">
                                                <h6>Private - Standard (Car)</h6>
                                            </div>
                                            <div className="AhbBottomStartRightCenter">
                                                <div className="SmallBox">
                                                    <h6>Thursday, Dec 8</h6>
                                                    <p>Delhi, Indira Gandhi Int.</p>
                                                </div>
                                                <div className="SmallBox">
                                                    <figure>
                                                        <img src="images/location_center.png" />
                                                    </figure>
                                                </div>
                                                <div className="SmallBox">
                                                    <h6>Thursday, Dec 9</h6>
                                                    <p>Radisson Blu Hotel New Delhi Dwarka</p>
                                                </div>
                                            </div>
                                            <div className="AhbBottomStartRightBottom Transfer">
                                                <div className="OutboundSmall Green">
                                                    <figure>
                                                        <img src="images/send_img.png" />
                                                    </figure>
                                                    <h6>Free Cancellation</h6>
                                                    <p>Free Until 23:59 PM on 13/11/2023</p>
                                                </div>
                                                <a href="" className="OpenATicket">
                                                    Open a Ticket
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="AhbBottomCenter">
                                        <p>From-To</p>
                                        <h6>01/03/2023</h6>
                                    </div>
                                    <div className="AhbBottomEnd">
                                        <div className="d-flex flex-column align-items-end">
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Performa
                                            </a>
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Voucher
                                            </a>
                                            <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#CancelModal"
                                                className="BrandOutlineBtn"
                                            >
                                                Cancel Booking
                                            </a>
                                        </div>
                                        <div className="text-right">
                                            <p>Booking Total</p>
                                            <h3>
                                                $3,000 <span>Incl. tax</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="AccountHistoryBox">
                                <div className="AhbTop">
                                    <div className="AhbtSmall">
                                        <p>Booking Reference ID</p>
                                        <h5>6512-SD454-22</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Status</p>
                                        <h5 className="Cancelled">
                                            Cancelled{" "}
                                            <span>
                                                <img src="images/cancel_icon.png" />
                                                $30.00
                                            </span>
                                        </h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Agent Name</p>
                                        <h5>Sharnya</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Customer Name</p>
                                        <h5>Zeeshan</h5>
                                    </div>
                                    <div className="AhbtSmall">
                                        <p>Booking Date</p>
                                        <h5>12/10/2023</h5>
                                    </div>
                                </div>
                                <div className="AhbBottom">
                                    <div className="AhbBottomStart">
                                        <div className="AhbBottomStartLeft Transfer">
                                            <figure>
                                                <img src="images/car_big_1.png" />
                                            </figure>
                                        </div>
                                        <div className="AhbBottomStartRight">
                                            <div className="AhbBottomStartRightTop">
                                                <h6>Private - Standard (Car)</h6>
                                            </div>
                                            <div className="AhbBottomStartRightCenter">
                                                <div className="SmallBox">
                                                    <h6>Thursday, Dec 8</h6>
                                                    <p>Delhi, Indira Gandhi Int.</p>
                                                </div>
                                                <div className="SmallBox">
                                                    <figure>
                                                        <img src="images/location_center.png" />
                                                    </figure>
                                                </div>
                                                <div className="SmallBox">
                                                    <h6>Thursday, Dec 9</h6>
                                                    <p>Radisson Blu Hotel New Delhi Dwarka</p>
                                                </div>
                                            </div>
                                            <div className="AhbBottomStartRightBottom Transfer">
                                                <div className="OutboundSmall Green">
                                                    <figure>
                                                        <img src="images/send_img.png" />
                                                    </figure>
                                                    <h6>Free Cancellation</h6>
                                                    <p>Free Until 23:59 PM on 13/11/2023</p>
                                                </div>
                                                <a href="" className="OpenATicket">
                                                    Open a Ticket
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="AhbBottomCenter">
                                        <p>From-To</p>
                                        <h6>01/03/2023</h6>
                                    </div>
                                    <div className="AhbBottomEnd">
                                        <div className="d-flex flex-column align-items-end">
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Performa
                                            </a>
                                            <a href="javascript:void(0);" className="BrandBtn">
                                                Voucher
                                            </a>
                                        </div>
                                        <div className="text-right">
                                            <p>Booking Total</p>
                                            <h3>
                                                $3,000 <span>Incl. tax</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </sections>
            </main>
            <Footer />
        </div>
    );
};

export default HotelListingHistory;
