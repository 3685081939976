import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { isLoggedIn } from '../../utils/LoggedIn';

// const apiUrl = 'https://api.example.com/cities';

export const cancelPackageBooking = createAsyncThunk('PackageBooking/postPackageBooking', async (payload) => {
  console.log('payload===>', payload)
  const response = await axios.post(`https://swtclub.com:4300/api/v1/user/booking/status`, payload, {
    headers: {
      "Authorization": isLoggedIn("swtadmin")
    },
  });
  return response.data;
});

export const updatePaymentType = createAsyncThunk('updatePaymentBooking/postupdatePaymentBooking', async (payload) => {
  console.log('payload===>', payload)
  const response = await axios.put(`https://swtclub.com:4300/api/v1/user/package/status`, payload, {
    headers: {
      "Authorization": isLoggedIn("swtadmin")
    },
  });
  return response.data;
});