import React, { useEffect, useState } from 'react'
import Logo from '../assests/images/logo.png'
import "../App.css";
import { Link, useNavigate } from 'react-router-dom';
import PackageModal from './PackageModal';
import { isLoggedIn } from '../utils/LoggedIn';
import { useDispatch } from 'react-redux';
import { fetchStaticContent } from '../reduxToolKit/slices/staticContentApiSlice';

const Header = () => {
  const navigate = useNavigate()


  const routeTo = () => {
    console.log("header 1 logged in");
    navigate('/indexLoggedIn')
  }

  const handleClick = () => {
    console.log('On click executed')
  }

  const [showPackageModal, setShowPackageModel] = useState(false);
  return (
    <div>
      <header className='fixed-top header1'>
        <nav class="navbar navbar-expand-lg">
          <a class="navbar-brand" href="#"><img src={Logo} /></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
              <li class="nav-item">
                <Link class="nav-link active" to="/">Home</Link>
              </li>
              <li class="nav-item" >
                <Link class="nav-link" to='/about'>About Us</Link>
              </li>
              {/* <li class="nav-item">
                    <Link class="nav-link" to='/agentLogin'>Services</Link>
                  </li> */}
              <li class="nav-item">
                <Link class="nav-link"
                  //onClick={()=>setShowPackageModel(true)}
                  to='/agentLogin'
                  href='#'
                // data-toggle="modal" 
                // data-target="#CountryModal" 
                >Package</Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div class="NavbarLogin">
            {
              isLoggedIn("swtadmin") ? routeTo() : <Link to="/agentLogin">LogIn</Link>
            }
            {/* <Link to="/agentLogin">Agent Log In</Link> */}
            {/* <a class="SupplierLoginBtn">Supplier Login</a> */}
          </div>
        </nav>
      </header>
      <PackageModal setShowPackageModel={setShowPackageModel} showPackageModal={showPackageModal} />
    </div>
  )
}

export default Header