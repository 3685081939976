import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from 'react-redux';
import { fetchStaticContent } from "../reduxToolKit/slices/staticContentApiSlice";
import Header2 from "./Header2";
import { isLoggedIn } from "../utils/LoggedIn";

const About = () => {
  const dispatch = useDispatch()
  const about_us = useSelector((state) => state?.staticContentData?.StaticContentData?.data?.[4])

  console.log('about_us', about_us)

  useEffect(() => {

    dispatch(fetchStaticContent())
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll animation
    });
  }, [])

  const createAboutMarkup = () => {
    return { __html: about_us?.description }
  }
  console.log('token', isLoggedIn("swtadmin"))
  console.log("about--", about_us);
  return (
    <div>

      {
        isLoggedIn("swtadmin") ? <Header2 /> : <Header />
      }
      <main id="main" class="ProductMain">
        <section>
          <div class="FilterProductsArea">
            <div class="AboutArea mt-5">
              <div class="section-title">
                <h1>About Us</h1>
              </div>
              <p dangerouslySetInnerHTML={createAboutMarkup()} />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default About;
