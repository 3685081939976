import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from 'react-redux';
import { fetchStaticContent } from "../reduxToolKit/slices/staticContentApiSlice";
import axios from "axios";
import { isLoggedIn } from "../utils/LoggedIn";
import Header2 from "./Header2";

const Contactus = () => {

    const dispatch = useDispatch()
    const contactus = useSelector((state) => state?.staticContentData?.StaticContentData?.data?.[3])
    console.log('contact us', contactus)
    // const [about_us, setAbout_us] = useState('')

    // const getContactus =async ()=>{
    //     const response = await axios.get(`https://swtclub.com:4300/api/v1/admin/content/list-static-content/contact_us`, {
    //         headers: {
    //             "Authorization": isLoggedIn("swtadmin")
    //         },
    //     })
    //     console.log('Response of contact us', response) 
    // }
    // console.log('contact us', about_us)

    useEffect(() => {
        dispatch(fetchStaticContent())
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Smooth scroll animation
        });
    }, [])

    const createAboutMarkup = () => {
        return { __html: contactus?.description }
    }
    return (
        <div>


            {
                isLoggedIn("swtadmin") ? <Header2 /> : <Header />
            }

            <main id="main" class="ProductMain">
                <section>
                    <div class="FilterProductsArea">
                        <div class="AboutArea mt-5">
                            <div class="section-title">
                                <h1>Contact Us</h1>
                            </div>
                            <p
                                dangerouslySetInnerHTML={createAboutMarkup()}
                            />
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default Contactus
