import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../utils/LoggedIn';


export const getPerformaLink = createAsyncThunk('PerformaLink/getPerformaLink', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`https://swtclub.com:4300/api/v1/user/booking/proforma?bookingId=${payload}`, {
        headers: {
            "Authorization": isLoggedIn("swtadmin")
        },
    });
    return response.data;
});

export const getVoucherLink = createAsyncThunk('VoucherLink/getVoucherLink', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`https://swtclub.com:4300/api/v1/user/booking/invoice?bookingId=${payload}`, {
        headers: {
            "Authorization": isLoggedIn("swtadmin")
        },
    });
    return response.data;
});