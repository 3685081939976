import React, { useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import Modal from 'react-bootstrap/Modal';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import Header2 from "./Header2";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { packagePriceApi } from "../reduxToolKit/slices/packagePriceList";
import GoogleMapComponent from "./GoogleMapComponent";
import moment from "moment";
import SwtMap from "./SwtMap";
import { faqAction } from "../reduxToolKit/slices/confirmBooking";
import { toast } from "react-toastify";
import { packageDetailsApiSlice } from "../reduxToolKit/slices/packageDetailsApiSlice";

const initialState = {
  city: "",
  date: "",
  children: "",
  transferType: "",
  hotelType: "",
  tourDate: "",
  amount: 0
}



const containerStyle = {
  width: '100%',
  height: '600px'
};
const PackageDetails = () => {
  const [activeTab, SetActiveTavb] = useState('OverviewDetails')
  const { id } = useParams()

  const [packageType, setPackageType] = useState('Budget ')
  const [image, setImage] = useState({
    toggle: false,
    show: false,
    open: false,
  }
  )

  const [packageDetailsData, setPackageDetailsData] = useState({})
  console.log('res of package detail===============================>', packageDetailsData)


  const { show, open, toggle } = image

  const [printState, setPrintState] = useState(true)


  const [ImageModal, SetImageModal] = useState({ photoIndex: 0, isOpen: false })

  const { photoIndex, isOpen } = ImageModal



  // const shareUrl ='http://18.217.92.138/#/package_details';
  const shareUrl = `https://swtclub.com/#/package_details/${id}`
  const shareText = "Swt package"
  const [iState, updateState] = useState({ scrollStateName: "" })
  const [newstate, updateNewState] = useState(initialState)
  const [localCenter, setLocalCenter] = useState({
    lat: 28.5355,
    lng: 77.3910
  });
  const [adults, setAdults] = useState(1)
  const [childs, setchilds] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  //=============================map===========================




  const [errorMsg, setErrorMsg] = useState({})


  const priceData = useSelector((state) => state?.pacakgePrice?.packageList?.data?.result?.[0]?.packagePrice?.pricePerHead)

  console.log('price data', priceData)

  const price = packageDetailsData?.packagePrice?.filter((item) => item.roomType == "Budget ")
  const [selectedPrice, SetSelectedPrice] = useState(price?.[0]?.pricePerHead)
  const { state } = useLocation()



  const incAdult = () => {
    setAdults((prev) => prev + 1)
  }
  const decAdult = () => {
    if (adults > 1) {
      setAdults((prev) => prev - 1)
    }

  }


  const incChild = () => {
    setchilds((prev) => prev + 1)
  }
  const decChild = () => {
    if (childs > 0) {
      setchilds((prev) => prev - 1)
    }

  }
  const packageId = packageDetailsData?._id
  const scrollHandle = (id) => {
    console.log('scroll id', id)
    SetActiveTavb(id)
    const element = document.getElementById(id)
    updateState({ ...iState, scrollStateName: id })
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }


  const handleValidation = () => {
    let formIsValid = true;

    let errorMsg = {};


    // if (!state?.newstate?.date) {
    //   return false;
    // }

    // if (!state?.adults) {
    //   return false;
    // }

    // if (!state?.newstate?.transferType) {
    //   return false;
    // }

    // if (!state?.newstate?.hotelType) {
    //   return false;
    // }

    // if (!paymentType) {
    //   return false;
    // }

    if (!newstate.city) {
      errorMsg.city = "* Please select city";
      formIsValid = false;
    }




    // if (!newstate.transferType) {
    //   errorMsg.transferType = "* Please enter  transfer type";
    //   formIsValid = false;
    // }

    // if (!newstate.hotelType) {
    //   errorMsg.hotelType = "* Please enter  transfer type";
    //   formIsValid = false;
    // }


    if (!newstate.date) {
      errorMsg.date = "* Please select date";
      formIsValid = false;
    }


    setErrorMsg(errorMsg)


    return formIsValid;
  };


  const handleChange = (e, type, price) => {
    const { name, value } = e.target
    console.log('name', name, 'value', value)
    if (type === 'incChild') {
      updateNewState((prev) => {
        return {
          ...prev,
          [prev.adults]: prev.adults + 1
        }
      })
    }

    if (type === 'packageType') {
      SetSelectedPrice(price)
      setPackageType(e.target.value)

    }
    updateNewState({
      ...newstate,
      [name]: value
    })
  }

  // const calculateAmount = (price) => {
  //   if (adults) {
  //     return (price * adults).toFixed(2)
  //   } else {
  //     return price
  //   }
  // }

  useEffect(() => {
    dispatch(packagePriceApi({ id: '65a4d9bd529fb6ac6ba767de', budget: 'budget' }));
    setLocalCenter({
      lat: Number(packageDetailsData?.address?.latitude),
      lng: Number(packageDetailsData?.address?.longitude)
    });
  }, [])

  useEffect(() => {

    dispatch(packageDetailsApiSlice(id)).then((res) => {
      // console.log('res of package detail===============================>', res);
      setPackageDetailsData(res?.payload?.data?.result?.[0]);
    });


  }, [id])

  const handleprint = () => {
    setTimeout(() => {
      window.print()

      setPrintState(true)

    }, 500)


  }

  const handleSubmit = () => {
    let formIsValid = handleValidation()

    // state={{newstate, priceData, adults,  packageId, packageData: state }}



    if (formIsValid) {

      navigate('/bookPackage', { state: { state: newstate, priceData: selectedPrice, adults, childs, packageId, packageData: packageDetailsData, markupPrice: packageDetailsData?.markupPrice } })

    }
  }

  const tourDate = () => {
    console.log('new state', newstate?.date)
    const inputDate = new Date(newstate?.date);

    if (isNaN(inputDate)) {
      return "Invalid date format. Please provide a valid date string.";
    }

    // Add 2 days and 3 nights
    const daysToAdd = packageDetailsData?.numberOfNights?.totalNumberofNights;
    const nightsToAdd = packageDetailsData?.numberOfNights?.totalNumberofNights; // 1 night = 1 day
    const totalDaysToAdd = daysToAdd;

    // Calculate the new date
    inputDate.setDate(inputDate.getDate() + totalDaysToAdd);
    updateNewState({
      ...newstate,
      tourDate: inputDate.toISOString().split('T')[0],
    })
  }

  useEffect(() => {
    tourDate()
  }, [newstate.date])

  const handleImages = () => {
    setImage({ ...image, open: true })
  }


  function handleClose() {
    setImage({ ...image, open: false });
  }







  console.log(packageDetailsData, 'PACKAGETYPE===================>', packageDetailsData?.hotels)



  return (
    <>
      <div id="PackageDetailsHeropdf">

        <Header2 />

        <main id="main" class="FlightListingArea" >
          <section>

            <div class="PackageDetailsHero">
              <div class="PdhLeft">
                <h2 className="mt-5">{packageDetailsData?.packageTitle}
                  {" "}
                  <span id="dateBlock">{newstate?.date} to {newstate?.tourDate}</span>
                </h2>
                <div class="DaysBox">
                  <h3>
                    {" "}
                    {packageDetailsData?.numberOfNights?.totalNumberofNights + 1} Days{" "}
                    {packageDetailsData?.numberOfNights?.totalNumberofNights} Nights
                  </h3>{" "}
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;

                  <ul>
                    <li>
                      <span>
                        <img src={require("../assests/images/van_icon.png")} />
                      </span>{" "}
                      {packageDetailsData?.packageTransportationType}
                    </li>
                    <li>
                      <span>
                        <img src={require("../assests/images/winter_icon.png")} />
                      </span>{" "}
                      {packageDetailsData?.season}
                    </li>
                  </ul>
                </div>
                {printState &&
                  <p>
                    {packageDetailsData?.numberOfNights?.city?.map(
                      (item, index) =>
                        `${index == 0 ? "" : ","} ${item?.name}(${item?.noOfNights
                        }N)`
                    )}
                  </p>}
              </div>
              {printState &&

                <div class="PdhRight" id="Gallery">
                  <Link target="_blank" to={`https://web.whatsapp.com/send?text=${encodeURIComponent(shareText)}%20${encodeURIComponent(shareUrl)}`} class="ShareOnWhatsApp">
                    <img src={require("../assests/images/share_icon.png")} />
                    Share on Whatsapp
                  </Link>
                  {/* <a href="javascript:void(0);" class="ShareOnWhatsApp">
                    <img src={require("../assests/images/heart.png")} />
                    Like
                  </a> */}
                  <a href="javascript:void(0);" class="ShareOnWhatsApp mr-0" onClick={() => {
                    setPrintState(false)
                    handleprint()
                  }

                  }>
                    <img src={require("../assests/images/download_icon.png")} />
                  </a>
                </div>}
            </div>
            <div className="PdhImages" id=''>
              <div className="row">
                <div className="col-md-7">
                  <figure>
                    <img src={packageDetailsData?.packageBanner?.[0] || ""} />
                  </figure>
                </div>
                <div className="col-md-5" id='GalleryDetails'>
                  <div className=''>

                    {printState ?
                      <div className="row h-100">
                        {
                          packageDetailsData?.packageImages?.length > 0 &&
                          packageDetailsData?.packageImages?.map((item, i) => {
                            if (i <= 4) {
                              return (
                                <div className="col-md-6 " key={i}>
                                  <figure
                                    className="ShowAllPhotos"
                                    onClick={i === 3 ? (() => SetImageModal({ ...ImageModal, isOpen: true })) : undefined}
                                  >
                                    <img className="mt-2" src={i <= 3 ? item : ""} alt='' />
                                    {i === 3 && <figcaption>Show All photos</figcaption>}
                                  </figure>
                                </div>
                              )
                            }


                          })
                        }
                        {show && (
                          <div className="col-md-12">
                            <div className='all-photos'>
                              {packageDetailsData?.packageImages?.slice(6,)?.map((item, i) => (
                                <figure>
                                  <img key={i} src={item} alt="" />
                                </figure>
                              ))}
                            </div>
                            {/* <div onClick={()=>setImage({...image,show:!show})}>{show?"Hide All Photos":"Show All photos"}</div> */}
                          </div>
                        )}

                      </div> : ""}
                  </div>
                </div>
              </div>
            </div>

          </section>
          <section>
            <div class="row">

              <div class="col-md-9">
                <div class="PackageInformation">
                  {printState &&
                    <ul>
                      <li>
                        <a onClick={() => scrollHandle('OverviewDetails')} className={activeTab == "OverviewDetails" ? "Idactive" : ""} >Overview</a>
                      </li>
                      <li>
                        <a onClick={() => scrollHandle('ItineraryDetails')} className={activeTab == "ItineraryDetails" ? "Idactive" : ""}>Itinerary</a>
                      </li>
                      <li>
                        <a onClick={() => scrollHandle('SightseeingDetails')} className={activeTab == "SightseeingDetails" ? "Idactive" : ""}>Sightseeing</a>
                      </li>
                      <li>
                        <a onClick={() => scrollHandle('Gallery')} className={activeTab == "Gallery" ? "Idactive" : ""}>Gallery</a>
                      </li>
                      <li>
                        <a onClick={() => scrollHandle('HotelsDetails')} className={activeTab == "HotelsDetails" ? "Idactive" : ""}>Hotels</a>
                      </li>
                      <li>
                        <a onClick={() => scrollHandle('CruiseDetails')} className={activeTab == "CruiseDetails" ? "Idactive" : ""}>Cruise</a>
                      </li>
                      <li>
                        <a onClick={() => scrollHandle('OptionalDetails')} className={activeTab == "OptionalDetails" ? "Idactive" : ""}>Optional</a>
                      </li>
                      <li>
                        <a onClick={() => scrollHandle('TourInformation')} className={activeTab == "TourInformation" ? "Idactive" : ""}>Tour Information</a>
                      </li>
                    </ul>}
                  <div class="OverviewDetails" id="OverviewDetails">
                    <h2>Overview</h2>
                    <div class="OdListing">

                      <div
                        dangerouslySetInnerHTML={{
                          __html: packageDetailsData?.packageOverview,
                        }}
                      ></div>
                    </div>
                    {/* <div class="OdListing">
                    <h6>Bali Island, Indonesia</h6>
                    <ul>
                      <li>
                        Tanjung Benoa Beach Water Activites ( 1x Parasailing,
                        Banana Ride &amp; Jetski )
                      </li>
                      <li>White Water Rafting At Ayung River</li>
                      <li>Tanah Lot Temple</li>
                    </ul>
                  </div>
                  <div class="OdListing">
                    <h2>Flight and transport</h2>
                    <ul>
                      <li>Return airport transfers on seat in coach basis</li>
                      <li>Return Cruise Transfers</li>
                      <li>All tours &amp; transfers on SIC basis</li>
                    </ul>
                  </div> */}
                    <div class="OdListing">
                      <h2>Meals</h2>
                      <ul>
                        <li>10 Breakfast</li>
                      </ul>
                      <div class="MealsBoxMain">
                        {packageDetailsData?.theIncludes?.includes("hotel") && (
                          <div class="MealsBox">
                            <span>
                              <img
                                src={require("../assests/images/meals-1.png")}
                              />
                            </span>
                            <h4>
                              Hotel <br /> Include
                            </h4>
                          </div>
                        )}
                        {packageDetailsData?.theIncludes?.includes("Cruise") && (
                          <div class="MealsBox">
                            <span>
                              <img
                                src={require("../assests/images/meals-2.png")}
                              />
                            </span>
                            <h4>
                              Cruise <br /> Include
                            </h4>
                          </div>
                        )}

                        {packageDetailsData?.theIncludes?.includes("Sightseeing") && (
                          <div class="MealsBox">
                            <span>
                              <img
                                src={require("../assests/images/meals-3.png")}
                              />
                            </span>
                            <h4>
                              Sightseeing <br /> Include
                            </h4>
                          </div>
                        )}

                        {packageDetailsData?.theIncludes?.includes("Meal") && (
                          <div class="MealsBox">
                            <span>
                              <img
                                src={require("../assests/images/meals-4.png")}
                              />
                            </span>
                            <h4>
                              Meal <br /> Include
                            </h4>
                          </div>
                        )}

                        {packageDetailsData?.theIncludes?.includes("Shared Van") && (
                          <div class="MealsBox">
                            <span>
                              <img
                                src={require("../assests/images/meals-5.png")}
                              />
                            </span>
                            <h4>
                              Shared Van <br /> Include
                            </h4>
                          </div>
                        )}

                        {packageDetailsData?.theIncludes?.includes("Private Cab") && (
                          <div class="MealsBox">
                            <span>
                              <img
                                src={require("../assests/images/meals-6.png")}
                              />
                            </span>
                            <h4>
                              Private Cab <br /> Include
                            </h4>
                          </div>
                        )}
                        {/* {state?.theIncludes?.includes('Guide') &&
                      <div class="MealsBox">
                        <span>
                          <img  src={require("../assests/images/meals-6.png" )}/>
                        </span>
                        <h4>
                        Guide <br /> Include
                        </h4>
                      </div>}
                      {state?.theIncludes?.includes('Visa') &&
                      <div class="MealsBox">
                        <span>
                          <img  src={require("../assests/images/meals-6.png" )}/>
                        </span>
                        <h4>
                    Visa <br /> Include
                        </h4>
                      </div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="ItineraryDetails" id="ItineraryDetails">
                    <h2>Day wise travel itinerary</h2>
                    {packageDetailsData?.itinerary?.map((item, i) => (
                      <div class="IdBox">
                        <h3>
                          Day {i + 1}:<span>{item?.title}</span>
                        </h3>
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.descriptionItinerary,
                            }}
                          ></div>
                        </p>
                        <p>
                          Included Meal:{" "}
                          {item?.MealsInclusions?.map(
                            (ele, index) => `${index == 0 ? "" : ","} ${ele}`
                          )}
                        </p>
                      </div>
                    ))}

                    <div class="PlacesVisitDetails">
                      <h2>Places you'll visit</h2>
                      <p>
                        You can see the location of the package you are going to
                        below
                        here
                      </p>
                      <div className="MapBox">
                        <figure>
                          <img
                            src={packageDetailsData?.mapImage}
                            width='850px'
                            height='400px'
                          />
                          {/* <figcaption>
                            <h4>Your destination</h4>
                            <p>
                              <span>Location</span>: {state?.address?.address}
                            </p>
                          </figcaption> */}
                        </figure>

                        {/* <SwtMap lat={(+state?.address?.latitude)} lng={(+state?.address?.longitude)} type={"details"} /> */}
                      </div>

                    </div>
                  </div>
                  <div class="SightseeingDetails" id="SightseeingDetails">
                    <h2>Sightseeing</h2>
                    <ul class="nav nav-tabs">
                      {packageDetailsData?.sightSeeing?.map((item, index) => (
                        <li class="nav-item">
                          <a
                            class={index == 0 ? "nav-link active" : "nav-link"}

                            href={`#sight${index}`}
                            data-toggle="tab"
                          >
                            {item?.CityName}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div class="tab-content">
                      {packageDetailsData?.sightSeeing?.map((item, index) => (
                        <div class={index == 0 ? "tab-pane fade show active" : "tab-pane fade"} id={`sight${index}`}>

                          {item?.sightInfo?.map((ele) => (
                            <div class="SdMain">
                              <div class="SdLeft">
                                <figure>
                                  <img src={ele?.sightSeeingBanner?.[0]} />
                                </figure>
                              </div>
                              <div class="SdRight">
                                <h2>{ele?.sightseeingTitle} </h2>
                                <p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: ele?.sightseeingDescription,
                                    }}
                                  ></div>
                                </p>
                                {/* <a href="javascript:void(0);" class="EfiBtn">
                           Read More
                         </a> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div class="HotelsDetails" id="HotelsDetails">
                    <h2>Hotels test</h2>
                    <ul class="nav nav-tabs">
                      {packageDetailsData?.hotels?.map((item, index) =>
                        <li class="nav-item">
                          <a
                            href={`#Hotel${index}`}
                            class="nav-link"
                            data-toggle="tab"
                          >
                            {item?.cityName}
                          </a>
                        </li>
                      )}
                    </ul>
                    <div class="tab-content">
                      {packageDetailsData?.hotels?.[0]?.hotelDetails?.map((item, index) =>

                        <div class={index == 0 ? "tab-pane fade active show" : "tab-pane fade"} id={`Hotel${index}`}>
                          <div class="row">

                            {
                              item?.hotelBanner?.map((imgItem) => {
                                return (
                                  <div class="HotelsBox mr-2">
                                    <figure  >
                                      <img src={imgItem} width='150px' height='150px' style={{ margin: '5px' }} />
                                      {/* <div class="HotelsBoxOverlay">
                                        <div class="d-flex align-items-center">
                                          <h6>4.0</h6>
                                          <i class="fa-solid fa-star"></i>
                                          <i class="fa-solid fa-star"></i>
                                          <i class="fa-solid fa-star"></i>
                                          <i class="fa-solid fa-star"></i>
                                          <i class="fa-solid fa-star"></i>
                                        </div>
                                      </div> */}
                                    </figure>
                                    <h5>{item?.hotelType}</h5>
                                    <h5>{item?.hotelName}</h5>
                                  </div>)
                              })
                            }
                          </div>
                        </div>)}
                    </div>
                  </div>

                  {
                    packageDetailsData?.cruise?.length > 0 ? packageDetailsData?.cruise?.map((item) =>
                      <div class="CruiseDetails" id='CruiseDetails'>
                        <h2>Cruise</h2>
                        <figure>
                          <img src={item?.CruiseImage?.[0]} />
                        </figure>
                        <h6>{item?.cruiseName}</h6>
                        <table>
                          <thead>
                            <tr>
                              <th>Day</th>

                              <th>City</th>
                              <th>Port Name</th>
                              <th>Arrival Time</th>
                              <th>Departure Time</th>
                            </tr>
                          </thead>
                          <tbody>{item?.schedules?.map((ele) =>
                            <tr>
                              <td>{ele?.date}</td>

                              <td>{ele?.cityName}</td>
                              <td>{ele?.portName}</td>

                              <td>{ele?.arrivalTime}</td>
                              <td>{ele?.departureTime}</td>

                            </tr>
                          )}


                          </tbody>
                        </table>
                        <p>
                          CRUISE RATES ARE VERY DYNAMIC, IT CAN BE VARY AT THE TIME OF
                          BOOKING
                        </p>
                      </div>
                    ) : 'Cruise Not Available'
                  }

                  {/* {
                    packageDetailsData?.cruise?.map((item) =>
                      <div class="CruiseDetails" id='CruiseDetails'>
                        <h2>Cruise</h2>
                        <figure>
                          <img src={item?.CruiseImage?.[0]} />
                        </figure>
                        <h6>{item?.cruiseName}</h6>
                        <table>
                          <thead>
                            <tr>
                              <th>Day</th>

                              <th>City</th>
                              <th>Port Name</th>
                              <th>Arrival Time</th>
                              <th>Departure Time</th>
                            </tr>
                          </thead>
                          <tbody>{item?.schedules?.map((ele) =>
                            <tr>
                              <td>{ele?.date}</td>

                              <td>{ele?.cityName}</td>
                              <td>{ele?.portName}</td>

                              <td>{ele?.arrivalTime}</td>
                              <td>{ele?.departureTime}</td>

                            </tr>
                          )}


                          </tbody>
                        </table>
                        <p>
                          CRUISE RATES ARE VERY DYNAMIC, IT CAN BE VARY AT THE TIME OF
                          BOOKING
                        </p>
                      </div>
                    )
                  } */}

                  <div class="OptionalDetails" id="OptionalDetails">
                    <h2>Optional</h2>
                    <ul class="nav nav-tabs">
                      {packageDetailsData?.optionalInformation?.map((item, index) => (
                        <li class="nav-item">
                          <a
                            href={`#optional${index}`}
                            class={index == 0 ? "nav-link active" : "nav-link"}
                            data-toggle="tab"
                          >
                            {item?.title}
                          </a>
                        </li>
                      ))}                  </ul>
                    <div class="tab-content">
                      {packageDetailsData?.optionalInformation?.map((item, index) => (
                        <div class={index == 0 ? "tab-pane fade show active" : "tab-pane fade"} id={`optional${index}`}>
                          {/* <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Type keywords to search tour"
                            />
                          </div> */}
                          <div class="OptionalsBox">
                            <figure>
                              <img src={item?.uploadBanner?.[0]} />
                            </figure>
                            <div class="OptionalsBoxRight">
                              <div class="OptionalsBoxTop">
                                <div class="OptionalsBoxTopLeft">
                                  <h4>{item?.title}</h4>
                                  <p>
                                    Category:  {item?.category?.map(
                                      (ele, index) =>
                                        <span>{`${index == 0 ? "" : ","} ${ele}`}</span>
                                    )}
                                  </p>
                                  <p>
                                    Sight Seeing Type: <span>Ticket Only</span>
                                  </p>
                                </div>
                                {/* <div class="OptionalsBoxBottom">
                              <a class="EfiBtn">
                                See Description
                              </a>
                              <a class="BrandBtn">
                                Add
                              </a>
                            </div> */}
                              </div>
                            </div>
                          </div>

                        </div>))}

                    </div>
                  </div>
                  <div class="TourInformation" id="TourInformation">
                    <h2>Tour Information</h2>
                    <div class="OdListing">
                      <h2>Sightseeing</h2>
                      <h6>Inclusions</h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: packageDetailsData?.
                            inclusions,
                        }}
                      ></div>
                    </div>
                    <div class="OdListing">
                      <h6>Exclusions</h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: packageDetailsData?.
                            exclusions,
                        }}
                      ></div>
                    </div>
                    <div class="OdListing">
                      <h6>Payment Policy</h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: packageDetailsData?.
                            paymentPolicy,
                        }}
                      ></div>
                    </div>
                    <div class="OdListing">
                      <h6>Cancellation Policy</h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: packageDetailsData?.
                            cancellationPolicy,
                        }}
                      ></div>

                    </div>
                  </div>
                  <div class="TcsTermsAndConditions">
                    <h2>TCS Terms and Conditions</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: packageDetailsData?.
                          termConditions,
                      }}
                    ></div>

                  </div>
                </div>
              </div>
              <div class="col-md-3">

                {printState &&
                  <div class="CalculatePrice">
                    <div class="Heading">
                      <h4>Calculate Your Price</h4>
                    </div>
                    <div class="form-group">
                      <h6>
                        Departure City<span>*</span>
                      </h6>
                      <select class="form-control" name="city" onChange={handleChange}>
                        <option value={''} >Select city</option>
                        {packageDetailsData?.numberOfNights?.city?.map((ele) =>

                          <option value={ele?.name}>{ele?.name}</option>
                        )}

                      </select>

                      {errorMsg.city && !newstate.city && (
                        <p style={{ color: "red" }}>{errorMsg.city}</p>
                      )}
                    </div>
                    <div class="form-group">
                      <h6>
                        Select date<span>*</span>
                      </h6>
                      {
                        packageDetailsData?.packageType === 'FIT package' ? <input
                          type="date"
                          class="form-control"
                          name="date"
                          min={moment(new Date()).format("YYYY-MM-DD")}

                          value={newstate.date}
                          onChange={handleChange}
                        /> : <select class="form-control" name="date" value={newstate.date} onChange={handleChange}>
                          <option value="">Select Date</option>
                          {
                            packageDetailsData?.groupPackage?.length > 0 && packageDetailsData?.groupPackage?.map((item) => {
                              return (
                                <option value={item?.date}>{moment(item?.date).format('DD-MMM-YYYY')}</option>
                              )
                            })
                          }
                        </select>
                      }


                      {errorMsg.date && !newstate.date && (
                        <p style={{ color: "red" }}>{errorMsg.date}</p>
                      )}
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <h6>
                            Adult<span></span>
                          </h6>
                          <div class="PlusMinusBox">
                            <span class="Icon" onClick={decAdult} decAdult name='adults'>
                              <img src={require("../assests/images/minus_red.png")} />
                            </span >
                            <h6>{adults}</h6>
                            <span class="Icon" onClick={incAdult} incAdult name='adults' >
                              <img src={require("../assests/images/plus_Red.png")} />
                            </span>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <h6>
                            Child<span>*</span>
                          </h6>
                          <div class="PlusMinusBox">
                            <span class="Icon">
                              <img src={require("../assests/images/minus_grey.png")} onClick={decChild} name='children' />
                            </span>
                            <h6>{childs}</h6>
                            <span class="Icon">
                              <img src={require("../assests/images/plus_Red.png")} onClick={incChild} name='children' />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="TransferBox">
                      <h6>Transfer</h6>
                      <div class="d-flex">
                        <div class="form-group">
                          <label class="Radio">
                            {" "}
                            {packageDetailsData?.packageTransportationType}
                            {/* <input type="radio" name="transferType" value="Private" onChange={handleChange} />
                            <span class="checkmark"></span> */}
                          </label>
                        </div>

                        {/* <div class="form-group ml-4">
                          <label class="Radio">
                            {" "}
                            Shared
                            <input type="radio" name="transferType" value='Shared' onChange={handleChange} />
                            <span class="checkmark"></span>
                          </label>
                        </div> */}

                      </div>

                      {errorMsg.transferType && !newstate.transferType && (
                        <p style={{ color: "red" }}>{errorMsg.transferType}</p>
                      )}
                      {/* <div class="form-group">
                    <h6>
                      Number of person and vehicle<span>*</span>
                    </h6>
                    <select class="form-control">
                      <option selected="">select</option>
                    </select>
                  </div> */}
                    </div>
                    <div class="HotelTypeArea" >
                      <h6>Hotel Type</h6>
                      <div class="d-flex flex-wrap">

                        {packageDetailsData?.packagePrice?.map((ele) =>
                          <div class="form-group ml-1">
                            <label class="Radio">
                              {" "}
                              {ele?.roomType}
                              <input type="radio" name="hotelType" checked={ele?.roomType == packageType ? true : false} onChange={(e) => handleChange(e, 'packageType', ele?.pricePerHead)} value={ele?.roomType} />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        )}







                      </div>
                      {errorMsg.hotelType && !newstate.hotelType && (
                        <p style={{ color: "red" }}>{errorMsg.hotelType}</p>
                      )}
                      <div class="HotelTypeLr">
                        <div class="HotelTypeLeft">
                          <span>Land Package</span>
                          <span>Private Vehicle</span>
                          <span>Budget Hotel</span>
                        </div>
                        <div class="HotelTypeRight">
                          <h2>Inr {selectedPrice}</h2>
                          <p>per person</p>
                          <p>*GST &amp; TCS Extra</p>
                        </div>
                      </div>
                      <a onClick={handleSubmit} class="BookNow">
                        Book Now
                      </a>
                      {/* <Link state={state} to='/quotation' class="SendEnquiry">
                        Send Enquiry
                      </Link> */}


                      <div class="ShareQuoteBox">


                        <Link target="_blank" to={`https://web.whatsapp.com/send?text=${""}`} class="ShareQuoteBtn">
                          Share Quote
                        </Link>
                        <a onClick={() => {
                          setPrintState(false)
                          handleprint()
                        }} class="DownloadIcon">
                          <img
                            src={require("../assests/images/download_icon.png")}
                          />
                        </a>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
          </section>
        </main>
        {printState &&
          <Footer />}
      </ div>
      {isOpen && (
        <Lightbox
          mainSrc={packageDetailsData?.packageImages[photoIndex]}
          nextSrc={packageDetailsData?.packageImages[(photoIndex + 1) % packageDetailsData?.packageImages.length]}
          prevSrc={packageDetailsData?.packageImages[(photoIndex + packageDetailsData?.packageImages.length - 1) % packageDetailsData?.packageImages.length]}
          onCloseRequest={() => SetImageModal({ isOpen: false, photoIndex })}
          onMovePrevRequest={() =>
            SetImageModal({
              photoIndex: (photoIndex + packageDetailsData?.packageImages.length - 1) % packageDetailsData?.packageImages.length, isOpen
            })
          }
          onMoveNextRequest={() =>
            SetImageModal({
              photoIndex: (photoIndex + 1) % packageDetailsData?.packageImages.length, isOpen
            })
          }
        />
      )}
      <Modal
        className="ModalBox ModalBoxNew"
        show={open}
        onHide={handleClose}>
        <Modal.Body>
          <div className='Category'>
            <a
              className="CloseModal"
              onClick={handleClose}
            >
              ×
            </a>
            <h3>GALLERY</h3>
            <div className="gallery-Images">
              {packageDetailsData?.packageImages?.map((item, i) => {
                return (
                  <figure className="ShowAllPhotos">
                    <img className="mt-2" src={item} alt='' />
                  </figure>
                )
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PackageDetails;
