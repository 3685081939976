import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { privateData } from '../../config/apiKey';
import { xSignature } from '../../config/x-signature';
import { isLoggedIn } from '../../utils/LoggedIn';

// const apiUrl = 'https://api.example.com/cities';

export const postPackageBooking = createAsyncThunk('PackageBooking/postPackageBooking', async (payload) => {
  console.log('payload===>', payload)
  const response = await axios.post(`https://swtclub.com:4300/api/v1/user/package/booked`, payload, {
    headers: {
      "Authorization": isLoggedIn("swtadmin")
    },
  });
  return response.data;
});


//=================== Package payment deduction =================
export const postPackagePayment = createAsyncThunk('Packagepayment/postPackagepayment', async (payload) => {
  console.log('payload===>', payload)
  const response = await axios.post(`https://swtclub.com:4300/api/v1/user/booking/amountDeduct`, payload, {
    headers: {
      "Authorization": isLoggedIn("swtadmin")
    },
  });
  return response.data;
});



export const addQuotation = createAsyncThunk('PackageBooking/postPackageBooking', async (payload) => {
  console.log('payload===>', payload)
  const response = await axios.post(`https://swtclub.com:4300/api/v1/user/qutation/add`, payload, {
    headers: {
      "Authorization": isLoggedIn("swtadmin")
    },
  });
  return response.data;
});