import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { packageApi } from '../reduxToolKit/slices/packageApiSlice'
import { isLoggedIn } from '../utils/LoggedIn'
import { useNavigate } from 'react-router-dom'

const PopularPackages = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const packageList = useSelector((state) => state?.packageList)
    console.log('packageList', packageList?.packageList?.data?.result)

    const handleClick = (item) => {
        console.log('clicked item', item, 'logged in', isLoggedIn('swtadmin'))
        if (isLoggedIn('swtadmin')) {
            navigate(`/package_details`, { state: item })//state={item}
        } else {
            navigate('/agentLogin')
        }

    }

    useEffect(() => {
        dispatch(packageApi())
    }, [])
    return (
        <div><section>
            <div className="PopularDestination">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h1>
                                Popular <span>Packages</span>{" "}
                            </h1>
                        </div>
                    </div>
                    {
                        packageList?.packageList?.data?.result?.slice(0, 6)?.map((item) => {
                            return (<div className="col-lg-4">
                                <a onClick={() => handleClick(item)}>
                                    <div className="PopularBox">
                                        <img src={item?.packageBanner?.[0]} />
                                        {/* <span>Recommended</span> */}
                                        <h2>{item?.packageTitle}</h2>
                                    </div>
                                </a>
                            </div>)
                        })
                    }
                </div>
            </div>
        </section>
        </div>
    )
}

export default PopularPackages