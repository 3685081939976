import React from 'react'
import { images } from '../utils/images'
import { Link } from 'react-router-dom'

const Footer = () => {

    const scrollHandler = (Id) => {
        const section = document.getElementById(Id)
            ;
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div>
            <footer>
                <div class="footer-top">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="footer-about">
                                <a href="index.html"><img src={images.logo} /></a>
                                <p>We are a Global DMC helping Travel Agents in India connect with travel service providers worldwide through an online interface.</p>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="footer-links">
                                        <h4>Follow Us</h4>
                                        <ul>
                                            <li><a href="https://www.instagram.com/swtclubindia/">
                                                <img src={images.instagramIcon} />Instagram</a>
                                            </li>
                                            <li><a href="https://x.com/ClubSwt">
                                                <img src={images.twitterIcon} />Twitter</a>
                                            </li>
                                            <li><a href="https://www.facebook.com/SWTCLUBINDIA">
                                                <img src={require('../../src/assests/images/facebookIcon.png')} />facebook</a>
                                            </li>
                                            {/* <li><a href="https://www.linkedin.com/company/swt-club/">
                                                <img src={require('../../src/assests/images/youtube.png')} />linkedin</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="footer-links">
                                        <h4>Quick Links</h4>
                                        <ul>
                                            <li><a onClick={() => scrollHandler('ourReach')}>Our Reach</a></li>
                                            <li><a onClick={() => scrollHandler('ourPartner')}>Our Partner</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="footer-contact">
                                        <h4>Contact Us</h4>
                                        <p><a href="mailto:info@swtclub.com">swtclub</a></p>
                                        <br />
                                        <p>+91 8800212080</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom animate fadeInUp" data-animate="fadeInUp" data-duration="1.5s" data-delay="0.3s" >
                    <ul>
                        <li><Link to='/termsAndConditions'>Terms & Conditions</Link></li>
                        <li><Link to='/privacyPolicy'>Privacy Policy</Link></li>
                    </ul>
                    <p>©2024 SWT All rights reserved</p>
                </div>
            </footer>
        </div>
    )
}

export default Footer