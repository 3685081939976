import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { privateData } from "../../config/apiKey";
import { xSignature } from "../../config/x-signature";
import { isLoggedIn } from "../../utils/LoggedIn";

// const apiUrl = 'https://api.example.com/cities'

export const packageDetailsApiSlice = createAsyncThunk(
    "packageDetails/postpackagePrice",
    async (payload) => {
        console.log("payload===>", payload);
        const response = await axios.get(
            `https://swtclub.com:4300/api/v1/user/package/detail?packageId=${payload}`, {},
            {
                headers: {
                    "Authorization": isLoggedIn("swtadmin")
                }
            }
        );
        return response.data
            ;
    }
);

const packageDetailsSlice = createSlice({
    name: "hotelList",
    initialState: {
        packageDetailsData: [],
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(packageDetailsApiSlice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(packageDetailsApiSlice.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.packageDetailsData = action.payload;
            })
            .addCase(packageDetailsApiSlice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default packageDetailsSlice.reducer;


